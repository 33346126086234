<template>
  <v-container class="site_container">
    <template>
      <h1 class="text-center">{{projects.project}}</h1>
      <br>
      <v-row justify="center">
        <v-btn
          color="green"
          dark
          @click="newImage"
        >
          New Image
        </v-btn>
      </v-row>
    </template>
    <v-row>
        <v-col
          cols="12"
          md="4"
          sm="6"
          class="padding_heigh_30"
          v-for="(project, i) in projects.project_images"
          :key="project.id"
        >
          <v-img
            :alt="project.title"
            contain
            :src="url_api + '/saved/image/projects/' + project.image"
            transition="scale-transition"
          />
          <div class="text-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  color="green"
                  v-bind="attrs"
                  v-on="on"
                  @click="selectEditing (i)"
                >
                  <v-icon>mdi-file-upload</v-icon>
                </v-btn>
              </template>
              <span>Upload</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  :color="(project.active === '1')?'blue':'grey'"
                  v-bind="attrs"
                  v-on="on"
                  @click="selectVisible(i)"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
              <span>visible</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  color="red"
                  v-bind="attrs"
                  v-on="on"
                  @click="deleteData(project.id)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
      <template>
        <v-row justify="center">
          <v-dialog
            v-model="dialog"
            persistent
            max-width="600px"
          >
            <v-card>
              <v-card-title>
                <span class="text-h5">Load a project image</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-file-input
                        accept="image/*"
                        label="Select your image"
                        v-model="new_image"
                      ></v-file-input>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialog = false"
                >
                  Close
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="saveData()"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>
      <template>
      <v-row justify="center">

        <v-dialog
          v-model="message_alert"
          max-width="290"
        >
          <v-card>
            <v-card-title class="text-h5">
              {{message.title}}
            </v-card-title>

            <v-card-text>
              {{message.description}}
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="green darken-1"
                text
                @click="message_alert = false"
              >
                close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { URL_API, headersToken, headersTokenMultipart } from '../../control'
import axios from 'axios'
export default {
  created () {
    this.getProjects()
  },
  data () {
    return {
      url_api: URL_API,
      projects: [],
      new_image: null,
      project: '',
      dialog: false,
      id: null,
      key_editing: null,
      message: {},
      message_alert: false,
      project_id: this.$route.params.id
    }
  },
  methods: {
    async getProjects () {
      axios.get('/api/admin/projects/project/image/' + this.project_id, headersToken(this.$store.state.auth.token)).then((response) => {
        this.projects = response.data
      })
    },
    newImage () {
      this.key_editing = null
      this.dialog = true
      this.new_image = null
    },
    selectEditing (key) {
      this.key_editing = key
      this.new_image = null
      this.dialog = true
    },
    selectVisible (key) {
      this.key_editing = key
      this.new_image = null
      this.projects.project_images[this.key_editing].active = !(this.projects.project_images[this.key_editing].active === '1')
      this.saveData()
    },
    saveData () {
      let id = null
      let active = 1
      if (this.key_editing !== null) {
        id = this.projects.project_images[this.key_editing].id
        active = this.projects.project_images[this.key_editing].active
      }
      const formData = new FormData()
      formData.append('document_file_0', this.new_image)
      formData.append('id', id)
      formData.append('project_id', this.project_id)
      formData.append('active', active)
      axios.post('api/admin/projects/project/image',
        formData,
        headersTokenMultipart(this.$store.state.auth.token)
      ).then((response) => {
        this.getProjects()
        this.dialog = false
        this.message.title = 'Record saved successfully'
        this.message.description = 'click close to continue'
        this.message_alert = true
      })
    },
    deleteData (id) {
      axios.delete('api/admin/projects/project/image/' + id,
        headersToken(this.$store.state.auth.token)
      ).then((response) => {
        this.getProjects()
        this.message.title = 'Record deleted successfully'
        this.message.description = 'click close to continue'
        this.message_alert = true
      })
    }
  }
}
</script>
